<div class="row justify-content-sm-center">

    <!-- Headline-->
    <div class="col-lg-12">
        <div class="row">
            <div class="card shadow p-3 bg-white rounded">
                <h4 class="card-title">
                    {{ motorhome.vehicleType }}-Wohnmobil<br>
                    {{ motorhome.brand }} {{ motorhome.model }}
                </h4>
                <h5 class="card-subtitle mb-2 text-muted">
                    {{ motorhome.sleepsites }} Schlafplätze
                </h5>
            </div>
        </div>
    </div>

    <!-- Bilder -->
    <div class="col-lg-12">
        <ngb-carousel class="content shadow p-3 bg-white rounded" >
            <ng-template ngbSlide *ngFor="let image of motorhome.images">
                <div class="picsum-img-wrapper">
                    <img [src]="image" alt="Random first slide" style="width: 100%" >
                </div>
            </ng-template>    
        </ngb-carousel>
    </div> 

    <!-- Spalte 1 Specs -->
    <div class="col-lg-6">
        <div class="row" *ngFor="let item of motorhome.contentList; let i = index;" >
            <div class="card shadow p-3 bg-white rounded" *ngIf="i < 3">
                <div class="card-body">
                    <h4 class="card-title">{{ item.title }}</h4>
                    <ul class="card-ul">
                        <li class="card-text" *ngFor="let contentItem of item.contentItems" [innerHTML]="contentItem"></li>
                    </ul>
                    <p *ngIf="item.contentText" [innerHTML]="item.contentText"></p>
                </div>
            </div>
        </div>
    </div>  

    <!-- Spalte 2 Specs -->
    <div class="col-lg-6">
        <div class="row" *ngFor="let item of motorhome.contentList; let i = index;" >
            <div class="card shadow p-3 bg-white rounded" *ngIf="i > 2">
                <div class="card-body">
                    <h4 class="card-title">{{ item.title }}</h4>
                    <ul class="card-ul">
                        <li class="card-text" *ngFor="let contentItem of item.contentItems" [innerHTML]="contentItem"></li>
                    </ul>
                    <p *ngIf="item.contentText" [innerHTML]="item.contentText"></p>
                </div>
            </div>
        </div>
    </div>

</div>