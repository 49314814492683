<nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3">
    <a class="navbar-brand" routerLink="/">Babel Vermietung</a>
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/" (click)="isMenuCollapsed = true">
            Startseite
        </a>
        </li>
        <li class="nav-item">
            <div ngbDropdown >                        
                <a class="nav-link" id="dropdown" ngbDropdownToggle>
                    Wohnmobile
                </a>
                <div ngbDropdownMenu aria-labelledby="dropdown" class="dropdown-menu-right">
                    <a (click)="onSelect(motorhome)" routerLink="motorhome.route" *ngFor="let motorhome of motorhomes" ngbDropdownItem>
                        {{ motorhome.brand }} {{ motorhome.model }} {{ motorhome.vehicleType }}
                    </a>
                </div>
            </div>
        </li>
      </ul>
    </div>
</nav>