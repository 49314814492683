import { Component, Input, OnInit  } from '@angular/core';
import SiteContent from '../assets/content.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'babel-vermietung';
  test = SiteContent.motorhome.length;
  


  constructor(){

  }

  ngOnInit(): void {
    
  }

}
