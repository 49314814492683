<div class="background">
    <div class="container">
        <div class="row justify-content-sm-center" style="padding-left: 15px; padding-right: 15px;">
  
            <!-- Adresse -->
            <div class="col-lg-4 footer-col">
                <a href="https://www.google.de/maps/place/50.941578, 7.659662" target="_blank">
                    <div class="col-xs-12">
                        <div class="row justify-content-sm-center">

                            <div class="row justify-content-sm-center content-block" style="margin: 8px;">
                                <div class="col-xs-1 item-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#778ca3" class="bi bi-geo-alt-fill icon" viewBox="0 0 16 16">
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                    </svg>
                                </div>
                                <div class="col-xs-11 item-block">
                                    <p class="text">
                                        Babel Vermietung<br>
                                        Inhaber: Jürgen Babel<br>
                                        Brüchermühler Straße 16b<br>
                                        51580 Reichshof
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </a>       
            </div>    
    
            <!-- Kontakt -->
            <div class="col-lg-4 footer-col">
                <div class="row justify-content-sm-center content-block">
                    <div class="col-xs-12">

                        <div class="row">
                            <div class="col-xs-1 item-block">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#778ca3" class="bi bi-telephone-fill icon" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                </svg>
                            </div>
                            <div class="col-xs-11 item-block">
                                <p class="text">            
                                    <a href="tel:+4916096855578" class="links">Mobil. 0160 / 968555 78</a>
                                </p>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col-xs-1 item-block">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#778ca3" class="bi bi-envelope-fill icon" viewBox="0 0 16 16">
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                                </svg>
                            </div>
                            <div class="col-xs-11 item-block">
                                <p class="text">
                                    <a href="mailto:juergen_babel@t-online.de" class="links">juergen_babel@t-online.de</a>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>       
            </div> 


            <div class="col-lg-4 footer-col">
                <div class="row justify-content-sm-center content-block">
                    <div class="col-xs-12">

                        <div class="row">
                            <div class="col-xs-12 item-block">
                                <p class="text">
                                    <a routerLink="/impressum" class="links">Impressum</a>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
  
        </div>
    </div>
  </div>