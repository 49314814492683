<app-navbar></app-navbar>

<div class="container-fluid site-background">
  <div class="container" style="padding:0">

    <router-outlet></router-outlet>

  </div>
</div>

<app-footer></app-footer>