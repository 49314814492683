<div class="row justify-content-sm-center shadow p-3 rounded tile">
    <div class="col-lg-4">
        <h4 class="card-title" [innerHTML]="siteContent.homePage.textTitle"></h4>


        <p [innerHTML]="siteContent.homePage.text" class="text-justify"></p>
    </div>
    <div class="col-lg-8">
        <ngb-carousel class="content bg-white" [showNavigationArrows]="false" [showNavigationIndicators]="false" >
            <ng-template ngbSlide *ngFor="let image of siteContent.homePage.images">
                <div class="picsum-img-wrapper">
                    <img [src]="image" alt="Random first slide" style="width: 100%" >
                </div>
            </ng-template>    
        </ngb-carousel>
    </div>
</div>

<div class="row justify-content-sm-center shadow p-3 rounded tile">
    <div class="col-lg-12">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d314.2545670497415!2d7.659602947587148!3d50.94153735312208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sde!4v1627667091046!5m2!1sde!2sde" width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</div>