import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import MhContent from '../../assets/content.json';

@Component({
  selector: 'app-motorhome',
  templateUrl: './motorhome.component.html',
  styleUrls: ['./motorhome.component.css']
})
export class MotorhomeComponent implements OnInit {
  public motorhome = MhContent.motorhome[0];

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {    
    this.route.params.subscribe((parm: Params) => {
      let id = parseInt(parm.id);
      this.motorhome = MhContent.motorhome[id];
    });   
  }

}
