import { Component, OnInit } from '@angular/core';
import SiteContent from '../../assets/content.json';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.css']
})
export class ImpressumComponent implements OnInit {
  public siteContent = SiteContent;

  constructor() { }

  ngOnInit(): void {
  }

}
