import { Component, OnInit } from '@angular/core';
import SiteContent from '../../assets/content.json';
import { Router } from '@angular/router'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public isMenuCollapsed = true;

  motorhomes = SiteContent.motorhome;

  links = [
    { title: 'One', fragment: 'one' },
    { title: 'Two', fragment: 'two' }
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onSelect(motorhome: any){
    this.router.navigate(['/wohnmobile', motorhome.id]);
  }

}
