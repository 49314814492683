<div class="col-lg-12">
    <div class="row ">
        <div class="card shadow p-3 bg-white rounded" >
            <div class="card-body">
                <h4 class="card-title">Impressum</h4>
                <p *ngFor="let pargraph of siteContent.impressumPargraph">
                    <small><strong [innerHTML]="pargraph.title"></strong></small>
                    <br>
                    <small [innerHTML]="pargraph.text"></small>
                </p>               
            </div>
        </div>
    </div>
</div>